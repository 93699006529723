.partners {
    background: #eff7f9;
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 60px 0px 80px 0px;
}

.logo_container {
    width: auto;
}

.active {
    border-bottom: none;
}

/* .carousel-control-prev-icon,
.carousel-control-next-icon {
height: 100px;
width: 100px;
outline: black;
background-size: 100%, 100%;
border-radius: 50%;
border: 1px solid black;
background-image: none;
}

.carousel-control-next-icon:after
{
content: '>';
font-size: 55px;
color: black;
}

.carousel-control-prev-icon:after {
content: '<';
font-size: 55px;
color: black;
} */

/* .partner_logo {
    width: 270px;
    padding: 10px 20px;
    margin: auto;
} */

/* @media (max-width: 768px) {
    .partner_logo {
        width: 220px;
        padding: 10px 20px;
        margin: auto;
    }
    .logo_container {
        width: 100%;
    }
} */
