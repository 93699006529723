.about {
    background: #eff7f9;
    margin-top: 80px;
    padding: 80px 0px;
}



.about_wrapper p {
    font-family: 'Lato';
    column-count: 3;
    font-size: 1.2rem;
    line-height: 2rem;
}

@media (max-width: 1024px) {
    .about_wrapper p {
        column-count: 2;
    }
}

@media (max-width: 768px) {
    .about {
        padding: 40px 0px;
    }
    .about_wrapper {
        text-align: center;
    }
    .about_wrapper p {
        column-count: 1;
        line-height: 1.6rem;
    }
}

