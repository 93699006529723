.footer {
    text-align: center;
}

.footer_body{
    padding-top: 20px;
}

.location {
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 10px;
}

.location span {
    color: #24c1fc;
    margin-right: 10px;
}
.ljubljana{
    margin-bottom: 30px;
}

.address {
    font-size: 1.1rem;
}

.form_fields input {
    background: #eff7f9;
    border: none;
    border-radius: 20px;
    height: 50px;
}
.form_fields textarea {
    background: #eff7f9;
    border: none;
    border-radius: 20px;
}
.form_fields button {
    margin-top: 30px;
}

.rights_reserved {
    opacity: .5;
}

