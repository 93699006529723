.person_card {
    margin: auto;
    margin-bottom: 30px;
    border: none;
    width: 17rem;
}
.person_card .card-body p {
    cursor: default;
}
.person_card .card-body h5 {
    cursor: default;
}
.person_card:hover {
    box-shadow: 0 0 9px lightgray;
}
.card-title {
    font-family: 'Didact Gothic', sans-serif;
    margin-bottom: 5px;
}
.card-text {
    margin-bottom: 8px;
}

.social_icon {
    font-size: 1.3rem;
    color: #24c1fc;
}
.social_icon:hover {
    color: #1e3141;
}
.fa-facebook-f {
    font-size: 1.1rem;
}

@media (max-width: 768px) {
    .person_card {
        width: 16rem;
    }
}

