.side-drawer {
    height: 100%;
    background: white;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;
    text-align: center;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
}

.side-drawer.open {
    transform: translateX(0);
}

.side-drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Didact Gothic', sans-serif;
    font-weight: 800;
}

.side-drawer li {
    margin: 0.5rem 0;
}

.side-drawer a {
    color: #1e3141;
    text-decoration: none;
    font-size: 1.5rem;
}

.side-drawer a:hover,
.side-drawer a:active {
    color: #24c1fc;
}


@media (min-width: 1081px) {
    .side-drawer {
        display: none;
    }
}

@media (max-width: 1080px) {
    .side-drawer {        
        box-shadow: none;
    }
}

