.skill_card {
    border: none;
    margin-bottom: 15px;
    box-shadow: 0 0 3px lightgray;
    border-radius: 10px;
}
.progress-bar, .progress {    
    border-radius: 10px;
    font-weight: 600;
}

.skill_card:hover {
    box-shadow: 0 0 9px rgb(156, 156, 156);
}
.skill_name {
    margin-bottom: 5px;
    font-family: 'Didact Gothic';
    font-weight: 600;
}

.skill_card_body {
    padding: 10px 15px;
}
.skill_card_body p {
    cursor: default;
}
.skill_card_body h5 {
    cursor: default;
}