.service_card {
    border: none;
    margin: auto;
    margin-bottom: 30px;
    padding-bottom: 10px;  
    padding-top: 10px;
    width: 17rem;
    text-align: center;
}

.service_card:hover h3 {
    color: #24c1fc;
}
.service_body h5 {
    text-transform: uppercase;
    margin-bottom: 25px;
    cursor: default;
}
.service_body p {
    cursor: default;
}


.service_card:hover {
    box-shadow: 0 0 9px lightgray;
}



.icon_img {
    padding: 10px;
    height: 100px;
    width: auto;
}

@media (max-width: 768px) {
    .service_card {
        width: 16rem;
    }
}