@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Didact+Gothic);

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Lato', sans-serif;
  color: #1e3141;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Didact Gothic', sans-serif;
  font-weight: 900;
}

p {
  font-family: 'Lato', sans-serif;
}

.outer_container {
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
}

.inner_container {
  width: 80%;
  max-width: 1400px;
  margin: 0 auto;
}

.section_skills {
  margin-top: 80px;
}

.subtitle {
  color: #24c1fc;
  font-size: 1.5rem;
  font-family: 'Lato', sans-serif;
  margin-bottom: 10px;
  font-weight: 200;
}

.title {
  color: #1e3141;
  font-size: 2rem;
  font-family: 'Didact Gothic', sans-serif;
  margin-bottom: 60px;
  font-weight: 900;
}

.btn_main {
  width: 200px;
  height: 50px;
  border: 3px solid #24c1fc;
  background: transparent;
  color: #24c1fc;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
}


.active_link {
  border-bottom: #24c1fc 3px solid;
}

.btn_main:focus { 
  outline: none; 
}
.btn:focus { 
  outline: none; 
}

.side-drawer {
    height: 100%;
    background: white;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;
    text-align: center;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.side-drawer.open {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.side-drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Didact Gothic', sans-serif;
    font-weight: 800;
}

.side-drawer li {
    margin: 0.5rem 0;
}

.side-drawer a {
    color: #1e3141;
    text-decoration: none;
    font-size: 1.5rem;
}

.side-drawer a:hover,
.side-drawer a:active {
    color: #24c1fc;
}


@media (min-width: 1081px) {
    .side-drawer {
        display: none;
    }
}

@media (max-width: 1080px) {
    .side-drawer {        
        box-shadow: none;
    }
}


.backdrop {
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background: rgba(0,0,0,0.3);
   z-index: 100;
}
.about {
    background: #eff7f9;
    margin-top: 80px;
    padding: 80px 0px;
}



.about_wrapper p {
    font-family: 'Lato';
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    font-size: 1.2rem;
    line-height: 2rem;
}

@media (max-width: 1024px) {
    .about_wrapper p {
        -webkit-column-count: 2;
           -moz-column-count: 2;
                column-count: 2;
    }
}

@media (max-width: 768px) {
    .about {
        padding: 40px 0px;
    }
    .about_wrapper {
        text-align: center;
    }
    .about_wrapper p {
        -webkit-column-count: 1;
           -moz-column-count: 1;
                column-count: 1;
        line-height: 1.6rem;
    }
}


.skill_card {
    border: none;
    margin-bottom: 15px;
    box-shadow: 0 0 3px lightgray;
    border-radius: 10px;
}
.progress-bar, .progress {    
    border-radius: 10px;
    font-weight: 600;
}

.skill_card:hover {
    box-shadow: 0 0 9px rgb(156, 156, 156);
}
.skill_name {
    margin-bottom: 5px;
    font-family: 'Didact Gothic';
    font-weight: 600;
}

.skill_card_body {
    padding: 10px 15px;
}
.skill_card_body p {
    cursor: default;
}
.skill_card_body h5 {
    cursor: default;
}
.person_card {
    margin: auto;
    margin-bottom: 30px;
    border: none;
    width: 17rem;
}
.person_card .card-body p {
    cursor: default;
}
.person_card .card-body h5 {
    cursor: default;
}
.person_card:hover {
    box-shadow: 0 0 9px lightgray;
}
.card-title {
    font-family: 'Didact Gothic', sans-serif;
    margin-bottom: 5px;
}
.card-text {
    margin-bottom: 8px;
}

.social_icon {
    font-size: 1.3rem;
    color: #24c1fc;
}
.social_icon:hover {
    color: #1e3141;
}
.fa-facebook-f {
    font-size: 1.1rem;
}

@media (max-width: 768px) {
    .person_card {
        width: 16rem;
    }
}


.people_subtitle , .people_title {
    text-align: center;
}

.people_section {
    margin-top: 80px;
    margin-bottom: 80px;
}
.partners {
    background: #eff7f9;
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 60px 0px 80px 0px;
}

.logo_container {
    width: auto;
}

.active {
    border-bottom: none;
}

/* .carousel-control-prev-icon,
.carousel-control-next-icon {
height: 100px;
width: 100px;
outline: black;
background-size: 100%, 100%;
border-radius: 50%;
border: 1px solid black;
background-image: none;
}

.carousel-control-next-icon:after
{
content: '>';
font-size: 55px;
color: black;
}

.carousel-control-prev-icon:after {
content: '<';
font-size: 55px;
color: black;
} */

/* .partner_logo {
    width: 270px;
    padding: 10px 20px;
    margin: auto;
} */

/* @media (max-width: 768px) {
    .partner_logo {
        width: 220px;
        padding: 10px 20px;
        margin: auto;
    }
    .logo_container {
        width: 100%;
    }
} */

.main {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_text {
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main_text h1 {
  font-family: "Didact Gothic", sans-serif;
  font-size: 2.5rem;
  color: #1e3141;
  margin-bottom: 30px;
}

.main_text p {
  font-size: 1.2rem;
  color: #1e3141;
  margin-bottom: 30px;
}

.main_pic {
  width: 100%;
}

.main_pic img {
  width: 100%;
}

.btn_main:hover,
.btn_main:active {
  color: white;
  background: #24c1fc;
  border: none;
}

.scroll-down {
  position: absolute;
  bottom: 60px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 40px;
  height: 60px;
  border-radius: 50px;
  border: 3px solid #1e3141;
  background: transparent;
  text-align: center;
  line-height: 60px;
  color: #1e3141;
}

.scroll-down i {
  -webkit-transition: 1s;
  transition: 1s;
}

.scroll-down:hover i {
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.scroll-down i {
  font-size: 20px;
}

@media (max-width: 768px) {
  .main_content {
    margin-top: 0;
  }

  .main_text {
    text-align: center;
  }

  .btn_main {
    margin: auto;
  }

  .main_text {
    padding: 0;
  }

  .main_text h1 {
    margin-bottom: 10px;
    line-height: 2rem;
    font-size: 1.6rem;
  }

  .main_text p {
    margin-bottom: 10px;
  }

  .scroll-down {
    display: none;
  }
  .main_pic {
    display: none;
  }
}

.toggle-button {
    position: absolute;
    top: 19px;
    left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 36px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    margin-left: 0;
}

.toggle-button:focus {
    outline: none;
}

.toggle-button__line {
    width: 30px;
    height: 3px;
    background: #184f96;
}
.toolbar {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 70px;
    background: white;
    font-family: 'Didact Gothic', sans-serif;
    font-weight: 900;
    z-index: 2;
}

/* .toolbar__navigation {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 40px;
} */

.toolbar__logo {
    position: absolute;
    left: 5%;
    top: 0;
    margin-left: 0;
    height: 70px;    
    color: #1e3141;
    text-decoration: none;
    font-size: 2rem;
    font-weight: 900;
    display: flex;
    align-items: center;
}

.toolbar__logo .logo_img {
    height: 50px;
}

.toolbar_navigation_items {
    text-align: center;
}

.toolbar_navigation_items ul {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    line-height: 70px;
}

.toolbar_navigation_items li {
    padding: 0 1rem;
}

.toolbar_navigation_items a {
    font-size: 1.3rem;
    color: #1e3141;
    text-decoration: none;
    cursor: pointer;
}

.toolbar_navigation_items a:hover,
.toolbar_navigation_items a:hover {
    color: #24c1fc;
}


.buttonContainer {
    position: absolute;
    right: 5%;
    top: 0;
    width: 134px;
    height: 70px;
    display: flex;
    align-items: center;
}


.btn:hover, .btn:active {
    color: white;
    background: #24c1fc;
    border: none;
}

@media (max-width: 1080px) {
    .toolbar_navigation_items {
        display: none;
    }

    .buttonContainer {
        display: none;
    }

    .toolbar__logo {
        position: absolute;
        right: 5%;
        top: 0;
        left: auto;
        margin: 0;
    }
    .toolbar__logo .logo_img {
        align-items: flex-end;
        height: 32px;
    }

}

@media (min-width: 1081px) {
    .toolbar__toggle_button {
        display: none;
    }

    .toolbar__logo {
        margin-left: 0;
    }
}

.btn_small { 
  font-family: 'Lato', sans-serif;
  font-size: 1.05rem;
  font-weight: 600;    
  width: 134px;
  }



.service_card {
    border: none;
    margin: auto;
    margin-bottom: 30px;
    padding-bottom: 10px;  
    padding-top: 10px;
    width: 17rem;
    text-align: center;
}

.service_card:hover h3 {
    color: #24c1fc;
}
.service_body h5 {
    text-transform: uppercase;
    margin-bottom: 25px;
    cursor: default;
}
.service_body p {
    cursor: default;
}


.service_card:hover {
    box-shadow: 0 0 9px lightgray;
}



.icon_img {
    padding: 10px;
    height: 100px;
    width: auto;
}

@media (max-width: 768px) {
    .service_card {
        width: 16rem;
    }
}
.services {
    text-align: start;
}

.services_title, .services_subtitle {
    text-align: center;
}
.footer {
    text-align: center;
}

.footer_body{
    padding-top: 20px;
}

.location {
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 10px;
}

.location span {
    color: #24c1fc;
    margin-right: 10px;
}
.ljubljana{
    margin-bottom: 30px;
}

.address {
    font-size: 1.1rem;
}

.form_fields input {
    background: #eff7f9;
    border: none;
    border-radius: 20px;
    height: 50px;
}
.form_fields textarea {
    background: #eff7f9;
    border: none;
    border-radius: 20px;
}
.form_fields button {
    margin-top: 30px;
}

.rights_reserved {
    opacity: .5;
}


html {
    height: 100%;
}

body {
    height: 100%;
}
