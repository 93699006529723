.main {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_text {
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main_text h1 {
  font-family: "Didact Gothic", sans-serif;
  font-size: 2.5rem;
  color: #1e3141;
  margin-bottom: 30px;
}

.main_text p {
  font-size: 1.2rem;
  color: #1e3141;
  margin-bottom: 30px;
}

.main_pic {
  width: 100%;
}

.main_pic img {
  width: 100%;
}

.btn_main:hover,
.btn_main:active {
  color: white;
  background: #24c1fc;
  border: none;
}

.scroll-down {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 60px;
  border-radius: 50px;
  border: 3px solid #1e3141;
  background: transparent;
  text-align: center;
  line-height: 60px;
  color: #1e3141;
}

.scroll-down i {
  transition: 1s;
}

.scroll-down:hover i {
  transform: translateY(50%);
}

.scroll-down i {
  font-size: 20px;
}

@media (max-width: 768px) {
  .main_content {
    margin-top: 0;
  }

  .main_text {
    text-align: center;
  }

  .btn_main {
    margin: auto;
  }

  .main_text {
    padding: 0;
  }

  .main_text h1 {
    margin-bottom: 10px;
    line-height: 2rem;
    font-size: 1.6rem;
  }

  .main_text p {
    margin-bottom: 10px;
  }

  .scroll-down {
    display: none;
  }
  .main_pic {
    display: none;
  }
}
