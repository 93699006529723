.toolbar {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 70px;
    background: white;
    font-family: 'Didact Gothic', sans-serif;
    font-weight: 900;
    z-index: 2;
}

/* .toolbar__navigation {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 40px;
} */

.toolbar__logo {
    position: absolute;
    left: 5%;
    top: 0;
    margin-left: 0;
    height: 70px;    
    color: #1e3141;
    text-decoration: none;
    font-size: 2rem;
    font-weight: 900;
    display: flex;
    align-items: center;
}

.toolbar__logo .logo_img {
    height: 50px;
}

.toolbar_navigation_items {
    text-align: center;
}

.toolbar_navigation_items ul {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    line-height: 70px;
}

.toolbar_navigation_items li {
    padding: 0 1rem;
}

.toolbar_navigation_items a {
    font-size: 1.3rem;
    color: #1e3141;
    text-decoration: none;
    cursor: pointer;
}

.toolbar_navigation_items a:hover,
.toolbar_navigation_items a:hover {
    color: #24c1fc;
}


.buttonContainer {
    position: absolute;
    right: 5%;
    top: 0;
    width: 134px;
    height: 70px;
    display: flex;
    align-items: center;
}


.btn:hover, .btn:active {
    color: white;
    background: #24c1fc;
    border: none;
}

@media (max-width: 1080px) {
    .toolbar_navigation_items {
        display: none;
    }

    .buttonContainer {
        display: none;
    }

    .toolbar__logo {
        position: absolute;
        right: 5%;
        top: 0;
        left: auto;
        margin: 0;
    }
    .toolbar__logo .logo_img {
        align-items: flex-end;
        height: 32px;
    }

}

@media (min-width: 1081px) {
    .toolbar__toggle_button {
        display: none;
    }

    .toolbar__logo {
        margin-left: 0;
    }
}

.btn_small { 
  font-family: 'Lato', sans-serif;
  font-size: 1.05rem;
  font-weight: 600;    
  width: 134px;
  }


