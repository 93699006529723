
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Lato', sans-serif;
  color: #1e3141;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Didact Gothic', sans-serif;
  font-weight: 900;
}

p {
  font-family: 'Lato', sans-serif;
}

.outer_container {
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
}

.inner_container {
  width: 80%;
  max-width: 1400px;
  margin: 0 auto;
}

.section_skills {
  margin-top: 80px;
}

.subtitle {
  color: #24c1fc;
  font-size: 1.5rem;
  font-family: 'Lato', sans-serif;
  margin-bottom: 10px;
  font-weight: 200;
}

.title {
  color: #1e3141;
  font-size: 2rem;
  font-family: 'Didact Gothic', sans-serif;
  margin-bottom: 60px;
  font-weight: 900;
}

.btn_main {
  width: 200px;
  height: 50px;
  border: 3px solid #24c1fc;
  background: transparent;
  color: #24c1fc;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
}


.active_link {
  border-bottom: #24c1fc 3px solid;
}

.btn_main:focus { 
  outline: none; 
}
.btn:focus { 
  outline: none; 
}
